<template>
  <div class="login">
    <div class="login-left">
      <div class="login-left-inner">
        <div class="login-left-inner-logo">
          <img src="/img/smaaart-logo.svg" alt="logo" />
        </div>
        <div class="login-left-inner-text" :class="{ 'mb-5': !inspecpro }">
          <h1>
            {{ $t("COMMON.SIGN_IN") }}
            <span v-if="inspecpro"> Inspecpros </span>
          </h1>
          <p>{{ $t("COMMON.LOGIN_WITH_ACCOUNT") }}</p>
          <small class="mt-2" v-if="inspecpro">
            <router-link
              :to="{ name: 'Links' }"
              class="btn-link"
              style="cursor: pointer"
            >
              <i class="fal fa-arrow-left"></i>
              {{ $t("COMMON.OTHER_LOGIN") }}
            </router-link>
          </small>
        </div>

        <div v-if="inspecpro" class="login-left-inner-form">
          <div class="text-center">
            <h2 class="">{{ appConfig.config_manager_app_name }}</h2>
          </div>

          <form
            v-if="is_2fa_enabled"
            role="form"
            @submit.prevent="handleSubmitCodeVerification()"
          >
            <div v-if="error">
              <base-alert dismissible type="danger" icon="fa fa-exclamation">
                {{ error }}
              </base-alert>
            </div>

            <base-input
              alternative
              class="mb-3"
              name="Password"
              type="text"
              :placeholder="$t('COMMON.VERIFICATION_CODE')"
              v-model="code"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.code" />

            <div class="row resend">
              <span
                @click="handleSubmit"
                style="cursor: pointer"
                class="text-gray-dark"
              >
                <small>{{ $t("COMMON.RESEND_CODE") }}</small>
              </span>
            </div>

            <div class="submit-wrapper">
              <base-button native-type="submit">
                {{ $t("COMMON.SIGN_IN") }}
              </base-button>
            </div>
          </form>

          <form v-else role="form" @submit.prevent="handleSubmit()">
            <div v-if="error">
              <base-alert dismissible type="danger" icon="fa fa-exclamation">
                {{ error }}
              </base-alert>
            </div>

            <base-input
              alternative
              class="mb-3"
              name="Email"
              :placeholder="$t('COMMON.EMAIL')"
              label="Email"
              v-model="email"
            />
            <validation-error :errors="apiValidationErrors.email" />

            <base-input
              alternative
              class="mb-3"
              name="Password"
              type="password"
              :placeholder="$t('COMMON.PASSWORD')"
              :label="$t('COMMON.PASSWORD')"
              v-model="password"
            >
            </base-input>
            <validation-error :errors="apiValidationErrors.password" />

            <div class="stay-connected">
              <base-checkbox v-model="connected">
                <span>
                  <i class="far fa-check"></i>
                </span>
                Rester connecté
              </base-checkbox>
            </div>

            <div class="submit-wrapper">
              <base-button native-type="submit">
                {{ $t("COMMON.SIGN_IN") }}
              </base-button>
            </div>
          </form>
          <div class="forgot-password">
            <router-link :to="{ name: 'PasswordReset' }">
              <small>{{ $t("COMMON.FORGOT_PASSWORD") }}</small>
            </router-link>
          </div>
        </div>

        <div v-else class="login-left-inner-form">
          <div class="text-center">
            <h2 class="">{{ appConfig.config_manager_app_name }}</h2>
          </div>
          <div class="d-flex flex-column gap-12px">
            <div class="w-100">
              <a
                class="d-inline-flex btn w-100 align-items-center border-1px rounded-12px border-black"
                @click="inspecpro = true"
              >
                <span
                  class="w-38px h-38px d-inline-flex justify-items-center align-items-center mr-4"
                >
                  <img
                    src="/img/SMAAART_Login_Icon_INSPECPRO.svg"
                    alt="InspecPro logo"
                  />
                </span>
                <span class="text-black h3 m-0">
                  {{ $t("COMMON.SIGN_IN") }} - Inspecpros
                </span>
              </a>
            </div>
            <div class="w-100">
              <a
                class="d-inline-flex btn w-100 align-items-center border-1px rounded-12px border-black"
                href="https://evaluation.smaaart.ca/"
                target="_blank"
              >
                <span
                  class="w-38px h-38px d-inline-flex justify-items-center align-items-center mr-4"
                >
                  <img
                    src="/img/SMAAART_Login_Icon_EVALUATION.svg"
                    alt="Évaluation logo"
                  />
                </span>
                <span class="text-dark h3 m-0">
                  {{ $t("COMMON.SIGN_IN") }} - Évaluation
                </span>
              </a>
            </div>
            <div class="w-100">
              <a
                class="d-inline-flex btn w-100 align-items-center border-1px rounded-12px border-black"
                href="https://ess.teamdesk.net/secure/default.aspx"
                target="_blank"
              >
                <span
                  class="w-38px h-38px d-inline-flex justify-items-center align-items-center mr-4"
                >
                  <img
                    src="/img/SMAAART_Login_Icon_RECONDITIONING.svg"
                    alt="Reconditionnement logo"
                  />
                </span>
                <span class="text-black h3 m-0">
                  {{ $t("COMMON.SIGN_IN") }} - Reconditionnement
                </span>
              </a>
            </div>
            <div class="w-100">
              <a
                class="d-inline-flex btn w-100 align-items-center border-1px rounded-12px border-black"
                href="https://ess.teamdesk.net/secure/db/41451/overview.aspx?t=403203"
                target="_blank"
              >
                <span
                  class="w-38px h-38px d-inline-flex justify-items-center align-items-center mr-4"
                >
                  <img
                    src="/img/SMAAART_Login_Icon_PRICING.svg"
                    alt="Pricing logo"
                  />
                </span>
                <span class="text-dark h3 m-0">
                  {{ $t("COMMON.SIGN_IN") }} - Pricing
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-right">
      <img src="/img/login-image.webp" alt="login" />
      <lang-switcher />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { LangSwitcher } from "@/components";

export default {
  components: {
    ValidationError,
    LangSwitcher,
  },
  mixins: [formMixin],
  data: () => ({
    error: null,
    code: null,
    connected: false,
    is_2fa_enabled: false,
    email: "",
    password: "",

    inspecpro: true,
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
    ...mapGetters({
      appConfig: "appConfig/appConfig",
    }),
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
          Locale: this.$i18n.locale,
        },
      };

      try {
        const response = await this.$store.dispatch("login", {
          user,
          requestOptions,
        });
        if (!!response?.success) {
          this.is_2fa_enabled = !!response?.success;
          this.$notify({
            type: "info",
            //position: 'bottom-right',
            message: response?.message,
          });
        }
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0].detail;
        this.$notify({
          type: "danger",
          message: error?.response?.data?.errors?.[0].detail,
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },

    async handleSubmitCodeVerification() {
      this.error = null;
      const data = {
        data: {
          type: "users",
          attributes: {
            code: this.code,
          },
        },
      };

      try {
        await this.$store.dispatch("users/code_verification", data);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("COMMON.SUCCESS_VERIFICATION"),
        });
      } catch (error) {
        this.error = error?.response?.data?.errors?.[0]?.detail;
        this.$notify({
          type: "danger",
          message: this.$t("COMMON.ERROR_VERIFICATION"),
        });
        error?.response?.data?.errors
          ? this.setApiValidation(error?.response?.data?.errors)
          : null;
      }
    },
  },
};
</script>

<style scoped>
.w-38px {
  width: 38px;
}
.h-38px {
  height: 38px;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) /* #000000 */;
}
.rounded-12px {
  border-radius: 12px;
}
.border-1px {
  border-width: 1px;
  border-style: solid;
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) /* #000000 */;
}
.gap-12px {
  gap: 12px;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity)) /* #000000 */;
}
</style>
